import {RiDeleteBin6Line} from 'react-icons/ri';
import {
  DSButton as Button,
  DSPopover as Popover,
  Icon,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useToast,
} from 'spekit-ui';
import {topics, logging} from 'spekit-datalayer';
import {ITopic} from 'spekit-types';
import {track} from '../../../../utils/analytics';

interface IDeleteTopicProps {
  topic: ITopic;
  handleClose: () => void;
  forceRefresh: (route: string, search?: string) => void;
}

export const DeleteTopic = (props: IDeleteTopicProps) => {
  const {topic, handleClose, forceRefresh} = props;
  const toast = useToast();

  const handleDeleteTopic = async () => {
    try {
      await topics.deleteTopic(topic.id);
      toast({
        description: `Topic deleted`,
        variant: 'success',
      });
      track('Topic Deleted', {
        screen_name: 'Topic form',
        topic_name: topic.name,
      });
      handleClose();
      forceRefresh('/app/wiki/topics');
    } catch (error) {
      toast({
        description: `An error occurred, Please try again later.`,
        variant: 'error',
      });
      logging.capture(error);
    }
  };

  return (
    <Popover>
      {({isOpen, onClose}) => (
        <>
          <PopoverTrigger>
            <Button
              variant='icon'
              icon={<Icon as={RiDeleteBin6Line} />}
              size='medium'
              aria-label='Delete Topic'
              colorScheme='white'
              tooltipLabel='Delete Topic'
              tooltipPlacement='bottom'
              isActive={isOpen}
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverCloseButton />
            <PopoverHeader>Delete this Topic</PopoverHeader>
            <PopoverBody>
              Are you sure? Users in your org will no longer have access to this Topic and
              all related data with be lost.
            </PopoverBody>
            <PopoverFooter>
              <Button size='medium' variant='ghost' colorScheme='white' onClick={onClose}>
                Cancel
              </Button>
              <Button
                size='medium'
                variant='danger'
                colorScheme=''
                onClick={handleDeleteTopic}
              >
                Yes, delete
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
