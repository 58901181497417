import {IBrandConfigResponse, ICustomBrandingFormState} from 'spekit-types';
import {api} from '../api';

import {apiToState, stateToApi} from './transformer';

const BRAND_CONFIG_API = '/api/v1/brand_config/';

export const getBrandConfig = async () => {
  const {data} = await api.get<IBrandConfigResponse>(BRAND_CONFIG_API, {
    cache: false,
  });
  return apiToState(data);
};

export const updateBrandConfig = async (state: ICustomBrandingFormState) => {
  const payload = stateToApi(state);
  const {data} = await api.post<IBrandConfigResponse>(BRAND_CONFIG_API, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return apiToState(data);
};
