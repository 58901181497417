import React from 'react';
import {topics} from 'spekit-datalayer';
import {IOptionType, ISpekFromState, TContentType} from 'spekit-types';
import {ControlledSelect} from 'spekit-ui';
import {useFormContext} from 'react-hook-form';

interface ITopicPicker {
  type: TContentType | 'topic';
  label?: string;
  placeholder?: string;
  showTooltip?: boolean;
  topicToIgnore?: IOptionType;
}

export const TopicPicker = ({
  type,
  label = 'Topics',
  placeholder = 'Select Topics',
  showTooltip = true,
  topicToIgnore,
}: ITopicPicker) => {
  const {
    formState: {errors},
  } = useFormContext<ISpekFromState>();

  const getTopics = async (searchText: string) => {
    try {
      const topics_ = await topics.get(searchText, {allowedOnly: true});
      return topics_
        .filter((topic) => topic.value !== topicToIgnore?.value)
        .map((topic) => ({
          value: topic.value,
          label: topic.label,
        }));
    } catch (err) {
      return [];
    }
  };

  return (
    <ControlledSelect
      testId='topic-picker'
      menuTestId='topic-picker-menu'
      name='topics'
      id='topics'
      label={label}
      isRequired={type === 'business_term' || type === 'asset'}
      isAsync
      isMulti
      cacheOptions
      isInvalid={!!errors.topics}
      defaultOptions
      placeholder={placeholder}
      loadOptions={async (searchText: string) => getTopics(searchText)}
      labelTooltip={
        showTooltip
          ? 'Topics determine who can access this Spek and on which domains it appears'
          : ''
      }
    />
  );
};
