import {useQueries, useQuery, UseQueryResult} from '@tanstack/react-query';
import {
  useIntegrationApp,
  Connection,
  IntegrationAppClient,
} from '@integration-app/react';
import {useConnections} from './useConnections';
import {IDrive} from 'spekit-types';
import {INTEGRATION_KEY_GOOGLE, INTEGRATION_KEY_SHAREPOINT, MY_DRIVE} from '../constants';

const filterDrives = (data: IDrive[]) => {
  return data.filter(
    (drive: IDrive) =>
      !(drive.uri?.includes('portals') || drive.uri?.includes('appcatalog'))
  );
};

const getSharepointDrives = async (iApp: IntegrationAppClient) => {
  const size = 500;
  let from = 0;
  let hasMoreResults = true;
  const records: IDrive[] = [];

  while (hasMoreResults) {
    const response = await iApp
      .connection(INTEGRATION_KEY_SHAREPOINT)
      .action('list-drives-via-search')
      .run({from, size});
    const drives = response.output.records;

    records.push(...drives);

    hasMoreResults = response.output.moreResultsAvailable;

    from += size;
  }
  return records;
};

const useSharepointDrives = (
  connection: Connection,
  hasContentIntegrationSharepointFlag?: boolean
) => {
  const integrationApp = useIntegrationApp();
  const drives = useQuery({
    queryKey: ['drives', INTEGRATION_KEY_SHAREPOINT],
    queryFn: async () => getSharepointDrives(integrationApp),
    refetchOnWindowFocus: false,
    enabled: Boolean(
      connection && !connection.disconnected && hasContentIntegrationSharepointFlag
    ),
    staleTime: 5 * 60 * 1000,
    select: filterDrives,
  });
  return drives;
};

const useGoogleDrives = (connection: Connection) => {
  const integrationApp = useIntegrationApp();

  const drives = useQuery({
    staleTime: 5 * 60 * 1000,
    queryKey: ['drives', INTEGRATION_KEY_GOOGLE],
    queryFn: async () => {
      const response = await integrationApp
        .connection(INTEGRATION_KEY_GOOGLE)
        .action('list-drives')
        .run();
      const results = response.output.records.map(
        (result: any) => result.fields
      ) as IDrive[];
      return [MY_DRIVE, ...results];
    },
    enabled: Boolean(connection && !connection.disconnected),
    refetchOnWindowFocus: false,
  });

  return drives;
};

export const useDrives = (hasContentIntegrationSharepointFlag?: boolean) => {
  const {connections, stores} = useConnections(hasContentIntegrationSharepointFlag);

  const googleDrives = useGoogleDrives(connections[INTEGRATION_KEY_GOOGLE]);

  const sharepointDrives = useSharepointDrives(
    connections[INTEGRATION_KEY_SHAREPOINT],
    hasContentIntegrationSharepointFlag
  );

  const drives = [
    {
      drives: googleDrives,
      store: stores[0],
      connection: connections[INTEGRATION_KEY_GOOGLE],
    },
  ];

  if (hasContentIntegrationSharepointFlag) {
    drives.push({
      drives: sharepointDrives as UseQueryResult<IDrive[], any>,
      store: stores[1],
      connection: connections[INTEGRATION_KEY_SHAREPOINT],
    });
  }

  return drives;
};
