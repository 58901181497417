import {Controller, useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {RiDeleteBin6Line, RiInformationLine} from 'react-icons/ri';
import {
  DSButton as Button,
  EncapsulatedInput,
  Flex,
  Icon,
  Text,
  DSTooltip as Tooltip,
  Box,
  Checkbox,
} from 'spekit-ui';
import {Paths} from './Paths';
import type {ITopicModalState} from './TopicModal';

export const RelatedDomains = () => {
  const {control, setValue} = useFormContext<ITopicModalState>();
  const {
    fields: domains,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'domains',
  });

  const domainState = useWatch({control, name: 'domains'});

  const handleAddDomain = () => {
    append({
      name: '',
      includeSubdomain: false,
      includePaths: false,
      paths: [{value: ''}],
    });
  };

  const handleRemoveDomain = (index: number) => {
    remove(index);
  };

  const isRemovableDomain = domains.length > 1;

  const handleSplitDomainAndPath = (domainUrl: string) => {
    try {
      const url = new URL(domainUrl);
      return {
        origin: url.origin,
        path: url.pathname === '/' ? '' : url.pathname,
      };
    } catch {
      return {origin: domainUrl, path: ''};
    }
  };

  const handleIncludePaths = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.checked && domainState?.[index]?.name) {
      const {origin, path} = handleSplitDomainAndPath(domainState[index].name);
      // Update domain name to only include origin
      setValue(`domains.${index}.name`, origin);
      // Update first path if path exists
      if (path) {
        setValue(`domains.${index}.paths.0.value`, path);
      }
    }
  };

  return (
    <Flex flexDirection='column' width='50%'>
      <Flex alignItems='center' gap={4} mb={16}>
        <Text variant='body1' fontWeight={600}>
          Related domains
        </Text>
        <Tooltip
          label={`Display the most relevant Topics on any site. When a user opens the Spekit Chrome sidebar on a related domain, they'll only see Topics relevant to that domain.`}
          placement='bottom'
          shouldWrapChildren
        >
          <Box display='flex' alignItems='center'>
            <Icon as={RiInformationLine} color='neutral.800' height='16px' width='16px' />
          </Box>
        </Tooltip>
      </Flex>
      <Flex direction='column' gap={24}>
        {domains.map((domain, index) => (
          <Box key={domain.id}>
            <Flex gap={12}>
              <Controller
                name={`domains.${index}.name`}
                control={control}
                render={({field, fieldState}) => (
                  <>
                    <EncapsulatedInput
                      {...field}
                      placeholder='https://'
                      label='URL'
                      isInvalid={!!fieldState.error}
                      errorMessage={fieldState.error?.message}
                    />
                    {isRemovableDomain && (
                      <Button
                        onClick={() => handleRemoveDomain(index)}
                        variant='icon'
                        icon={<Icon as={RiDeleteBin6Line} />}
                        size='medium'
                        colorScheme='white'
                        aria-label='Delete domain'
                        data-testid='delete-domain-button'
                        tooltipLabel='Delete URL'
                        tooltipPlacement='bottom'
                        alignSelf={!!fieldState.error ? 'center' : 'flex-end'}
                        mb={!fieldState.error ? 6 : 0}
                        mt={!!fieldState.error ? 6 : 0}
                      />
                    )}
                  </>
                )}
              />
            </Flex>
            <Flex direction='column' gap={12} mt={12}>
              <Controller
                name={`domains.${index}.includeSubdomain`}
                control={control}
                render={({field: {value, onChange}}) => (
                  <Checkbox isChecked={value} onChange={onChange}>
                    Include subdomain and domain
                  </Checkbox>
                )}
              />
              <Controller
                name={`domains.${index}.includePaths`}
                control={control}
                render={({field: {value, onChange}}) => (
                  <Checkbox
                    isChecked={value}
                    onChange={(e) => {
                      onChange(e);
                      handleIncludePaths(e, index);
                    }}
                  >
                    Include path and domain
                  </Checkbox>
                )}
              />

              {domainState?.[index]?.includePaths && (
                <Paths urlIndex={index} isRemovableDomain={isRemovableDomain} />
              )}
            </Flex>
          </Box>
        ))}
      </Flex>

      <Button
        variant='outlined'
        onClick={handleAddDomain}
        size='medium'
        mt={24}
        width='fit-content'
      >
        Add another domain
      </Button>
    </Flex>
  );
};
