import {lazy, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import {IFlagState, ISession} from 'spekit-types';

interface IState {
  flag: IFlagState;
  layout: {
    me: ISession;
  };
}

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const ReportBuilder = lazy(() => import('./pages/ReportBuilder/ReportBuilder'));
const ShareLinksByCompany = lazy(() => import('./pages/ShareLinks/ShareLinksByCompany'));
const CompanyDetails = lazy(() => import('./pages/ShareLinks/CompanyDetails'));

const AnalyticsRoutes = () => {
  const flags = useSelector((state: IState) => state.flag);
  const userId = useSelector((state: IState) => state.layout.me.id);
  const role = useSelector((state: IState) => state.layout.me.role);

  const getShareLinkAnalyticRoutes = () => {
    if (!flags.hasSpekLinkGenerationFlag) {
      return <Redirect to='/app/wiki/topics' />;
    }

    return <ShareLinksByCompany />;
  };

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path='/app/analytics/ai-analytics-dashboard'>
          {flags.hasRecommendationsFlag && flags.hasRexChatFlag ? (
            <Dashboard title='AI Analytics (New)' dashboardSerial='am1DXZk1wO' />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
        <Route path='/app/analytics/activation-dashboard'>
          <Dashboard
            title='User Activation Dashboard'
            dashboardSerial='GnAkEgax3l'
            parameters={{user_id_embed: userId, user_role_embed: role}}
          />
        </Route>
        <Route path='/app/analytics/adoption-dashboard'>
          <Dashboard
            title='Adoption Dashboard'
            dashboardSerial='ovARWDnYlV'
            parameters={{user_id_embed: userId, user_role_embed: role}}
          />
        </Route>
        <Route path='/app/analytics/governance-dashboard'>
          <Dashboard
            title='Governance Dashboard'
            dashboardSerial='OVxbPEpADm'
            parameters={{user_id_embed: userId, user_role_embed: role}}
          />
        </Route>
        <Route path='/app/analytics/content-creation-dashboard'>
          <Dashboard
            title='Content Creation Dashboard'
            dashboardSerial='V4ArawQ1mD'
            parameters={{user_id_embed: userId, user_role_embed: role}}
          />
        </Route>
        <Route path='/app/analytics/flows-dashboard'>
          {flags.hasFlowFlag ? (
            <Dashboard title='Flows Dashboard' dashboardSerial='QRA3vdK1Ed' />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
        <Route path='/app/analytics/knowledge-checks-dashboard'>
          {flags.hasQuizFlag ? (
            <Dashboard
              title='Knowledge Checks Dashboard'
              dashboardSerial='PD1jaElxvm'
              parameters={{user_id_embed: userId, user_role_embed: role}}
            />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
        <Route path='/app/analytics/share-link-activity'>
          {getShareLinkAnalyticRoutes()}
        </Route>
        <Route path='/app/analytics/company-detail'>
          {flags.hasSpekLinkGenerationFlag ? (
            <CompanyDetails />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>

        <Route path='/app/analytics/spotlights-dashboard'>
          <Dashboard
            title='Spotlights Dashboard'
            dashboardSerial='7Jx6BLnxwe'
            parameters={{user_id_embed: userId, user_role_embed: role}}
          />
        </Route>
        <Route path='/app/analytics/smartsend-dashboard'>
          {flags.hasSpekLinkGenerationFlag ? (
            <Dashboard
              title='Buyer Engagement Dashboard'
              dashboardSerial={flags.hasSmartSendROIFlag ? 'am1D6gnYwO' : 'p9170DbAa3'}
            />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
        <Route path='/app/analytics/custom-reports'>
          {flags.hasCustomReportsDashboardFlag ? (
            <ReportBuilder title='Custom Reports' reportSerial='zOVxbyYDmk' />
          ) : (
            <Redirect to='/app/wiki/topics' />
          )}
        </Route>
      </Switch>
    </Suspense>
  );
};

export default AnalyticsRoutes;
