import {lazy, Component, Fragment, Suspense} from 'react';
import {connect} from 'react-redux';
import SpotlightModal from 'spekit-shared-components/src/spotlightModal/spotlightModal';
import {stopAbly} from './utils/swBrowserUtils';
import {
  Popover,
  MenuItem,
  Modal,
  notifications,
  Fontawesome,
  getExtensionButton,
  Overlay,
} from 'spekit-ui';
import {
  updateUser,
  updateWikiStoredState,
  updateUnreadCount,
  setRoles,
  setFieldTypes,
  setUserStages,
  closeSpotlightModal,
  cloneSpek,
  createSpekWithTopic,
  showSpotlightModal,
  createSpotlightWithFile,
  showTopicModal,
  setFlags,
  setFeatures,
  showPaywall,
  showCreateSpekModal,
  hideCreateSpekModal,
  showAssetUploadModal,
  hideAssetUploadModal,
  setAsyncSpotlightCreate,
  showContentImportModal,
  hideContentImportModal,
} from './redux/actions';
import {Redirect, withRouter} from 'react-router-dom';
import StateManager from './stateManager';
import DemoBanner from './demoBanner';
import RenewBanner from './renewBanner';
import {ChromeBanner} from './components/ChromeBanner';
import StopImpersonateBanner from './stopImpersonateBanner';
import * as permissions from './utils/permissions';
import {track, reset, identifySession} from './utils/analytics';
import {faUserCircle, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import notificationsClient from './notificationsClient';
import {
  utils,
  getFieldTypes,
  logging,
  user,
  FeatureFlags,
  getLicenseStatus,
  auth,
  getRoles,
  IS_IMPERSONATING,
  fetchMiddleWare,
} from 'spekit-datalayer';
import {PropagateLoader} from 'react-spinners';
import {
  StatusBar,
  AssetModal,
  isComponentVisible,
  ContentImportModal,
} from 'spekit-shared-components';
import {clear} from 'spekit-ui/src/utils/localStorage';

import {CreateSpekSpotlightWrapper} from './components/CreateSpekSpotlight';
import BusinessTermForm from './businessTermFormFlagged';
import SideNavigation, {getNavItems} from './components/SideNavigation';
import {TopicForm} from './features/Wiki/components/TopicForm';
import {TopicModal} from './features/Wiki/components/TopicModal';

import {AppNavigation} from './components/AppNavigation';

const fetch = fetchMiddleWare.fetchMiddleWare;
const {notify} = notifications;
const {getFeatures} = FeatureFlags;
const {extensionInstalled} = getExtensionButton;
const WithSpotlight = CreateSpekSpotlightWrapper(BusinessTermForm);

const UnlockPremium = lazy(() => import('./unlockPremium'));
const LimitExceeded = lazy(() => import('./limitExceeded'));
const LicensesExpired = lazy(() => import('./components/Licenses/LicensesExpired'));
const LicensesExceeded = lazy(() => import('./components/Licenses/LicensesExceeded'));
const AccountBlocked = lazy(() => import('./components/Licenses/AccountBlocked'));
const LockedOut = lazy(() => import('./lockedOut'));

const ConnectedSpotlightModal = connect(null, (dispatch) => {
  return {
    closeSpotlightModal: () => dispatch(closeSpotlightModal()),
  };
})(SpotlightModal);

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needLogin: false,
      menuOpen: false,
      menuAnchor: null,
      isExtension: false,
      stateUpdate: null,
      loading: true,
      searchTerm:
        utils.parseQs(this.props.location.search + this.props.location.hash).q ||
        utils.parseQs(this.props.location.search + this.props.location.hash).query ||
        '',
    };
    this.handleCogMenu = this.handleCogMenu.bind(this);
    this.logout = this.logout.bind(this);
    this.stopImpersonate = this.stopImpersonate.bind(this);
  }
  componentDidMount() {
    if (!this.props.me) {
      this.getSession().then(() => {
        notificationsClient.startNotificationsListener();
      });
    } else if (
      !this.props.me.is_demo &&
      !this.props.me.objects_imported &&
      this.props.me.company.salesforceorganizations_set.length > 0 &&
      this.props.location.pathname !== '/app/accounts/manageobjects'
    ) {
      this.props.history.replace('/app/accounts/manageobjects?onboard');
    } else if (
      this.props.me.company.salesforceorganizations_set.length === 0 &&
      this.props.location.pathname !== '/app/connectSF' &&
      !this.props.location.pathname.includes('connectSF')
    ) {
      this.props.history.replace('/app/connectSF/');
    }
    this.getChromeExtensionStatus();
  }

  componentDidUpdate(oldProps) {
    if (this.props.spotlightModal.isOpen) {
      const el = document.getElementById('spotlight-create-modal');
      isComponentVisible({
        element: el,
        message: 'Spotlight create modal is not visible via Webapp',
        tag: 'spotlight-modal-not-visible-web',
      });
    }

    const {me, roles, fieldTypeSelect, userStages, flag} = this.props;
    if (!me) {
      this.getSession();
    } else if (
      !me.is_demo &&
      !me.objects_imported &&
      me.company.salesforceorganizations_set.length > 0 &&
      this.props.location.pathname !== '/app/accounts/manageobjects'
    ) {
      this.props.history.replace('/app/accounts/manageobjects?onboard');
    } else if (
      me.company.salesforceorganizations_set.length === 0 &&
      this.props.location.pathname !== '/app/connectSF' &&
      !this.props.location.pathname.includes('connectSF')
    ) {
      this.props.history.replace('/app/connectSF');
    } else if (this.props.location.pathname !== oldProps.location.pathname) {
      this.setState({loading: false});
    } else if (
      me &&
      this.state.loading &&
      roles.hasLoaded &&
      fieldTypeSelect.hasLoaded &&
      userStages.hasLoaded &&
      flag.hasLoaded
    ) {
      this.setState({loading: false});
    }
    let indexingTask = oldProps.topicIndex.tasks.find((t) => {
      return !this.props.topicIndex.tasks.find((tt) => tt.id === t.id);
    });
    if (indexingTask) {
      if (this.props.topicIndex.status) {
        notify({text: indexingTask.label + ' update is complete'});
      } else {
        notify({error: true, text: indexingTask.label + ' could not be updated'});
      }
    }
  }

  goToWiki = () => {
    let queryFilters = utils.parseQs(
      this.props.location.search + this.props.location.hash
    );
    if (queryFilters.topic && queryFilters.tag) {
      this.props.history.push('/app/wiki/?' + utils.stringifyQs(queryFilters));
    }
  };

  fetchRoles = async () => {
    try {
      const result = await getRoles();
      this.props.setRoles(result.groups);
    } catch (err) {
      this.props.setRoles([]);
      logging.capture(err);
    }
  };

  fetchFieldTypes = async () => {
    try {
      const result = await getFieldTypes();
      this.props.setFieldTypes(result);
    } catch (err) {
      this.props.setFieldTypes([]);
      logging.capture(err);
    }
  };

  fetchFeatureFlags = async () => {
    try {
      const features = await getFeatures();
      this.props.setFlags(features);
      this.props.setFeatures(features);
    } catch (err) {
      this.props.setFlags({});
      this.props.setFeatures([]);
      logging.capture(err);
    }
  };
  handleSpotlightDelete = () => {
    this.props.closeSpotlightModal();
    this.props.history.push('/');
    this.props.history.replace('/app/spotlights');
  };
  handleSpotlightClose = () => {
    this.props.closeSpotlightModal();
  };
  fetchLicenseStatus = async () => {
    if (this.props.me?.role === 'Account Admin') {
      const response = await getLicenseStatus();
      if (response.show_message) {
        if (response.licenses_expired) {
          this.props.showPaywall('licensesExpired', 'soft');
        } else if (response.licenses_exceeded) {
          this.props.showPaywall('licensesExceeded', 'soft');
        }
      }
    }
  };
  fetchStages = async () => {
    try {
      const result = await user.getStages();
      if (result && result.stages && result.stages[this.props.me.role]) {
        delete result.stages[this.props.me.role].invite_data_expert;
      }
      const installed = await extensionInstalled();
      let userRole =
        this.props.me.role !== 'Super Admin' ? this.props.me.role : 'Account Admin';

      const modifiedStages = result.stages[userRole];
      if (modifiedStages) {
        modifiedStages.chrome_installed = installed;
      }
      this.props.setUserStages(modifiedStages);
    } catch (err) {
      this.props.setUserStages({});
      logging.capture(err);
    }
  };

  getChromeExtensionStatus = () => {
    extensionInstalled().then((installed) => {
      this.setState({isExtension: installed});
    });
  };
  getSession = async () => {
    try {
      const session = await auth.getSession();
      if (session) {
        identifySession(session);
        this.props.updateUser(session);
        this.props.updateUnreadCount(session.unread_notifications);
        this.fetchRoles();
        this.fetchFieldTypes();
        this.fetchStages();
        await this.fetchFeatureFlags();
        await this.fetchLicenseStatus();
      }
    } catch (err) {
      if (err.message !== 'not logged in') {
        logging.capture(err);
      } else {
        this.setState({needLogin: true});
      }
    }
  };

  handleNotify = (notif) => {
    notify(notif);
  };

  handleCogMenu(selection) {
    selection = selection.label;
    if (selection === 'Sign out') {
      this.logout();
    } else if (selection === 'Profile') {
      this.props.history.push('/app/settings/profile');
    }
    this.handleMenuClick();
  }
  logout() {
    const self = this;
    fetch('/api/auth/logout', {
      credentials: 'include',
    })
      .then((response) => {
        if (response.status === 200) {
          notificationsClient.stopNotificationListener();
          stopAbly();

          reset();
          clear();
          track('Logout', {
            screen_name: 'Logout',
            url: '/logout',
          });
          logging.setContext('Session', null);
          self.props.updateUser(null);
          window.location.reload(true);
        } else {
          throw new Error('Could not logout');
        }
      })
      .catch((err) => {
        notify({error: true, text: 'Please check your internet connection.'});
        logging.capture(err);
      });
  }

  stopImpersonate() {
    track('Stop Impersonating', {
      screen_name: 'Impersonating Banner',
      user: {email: this.props.me.email, username: this.props.me.username},
    });
    fetch('/impersonate/stop', {})
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem(IS_IMPERSONATING);
          window.location.reload(true);
        } else {
          throw new Error('Could not stop impersonate');
        }
      })
      .catch((err) => {
        notify({error: true, text: 'Please check your internet connection.'});
        logging.capture(err);
      });
  }

  handleMenuClick = (evt) => {
    if (evt && !this.state.menuOpen) {
      this.setState({
        menuOpen: true,
        menuAnchor: evt.target,
      });
    } else {
      this.setState({
        menuOpen: false,
        menuAnchor: null,
      });
    }
  };

  handleAssetClose = (properties) => {
    this.props.hideAssetUploadModal();

    if (!properties?.isSuccessful) return;

    if (properties?.notification?.createSpotlight && properties?.fileDetails) {
      const {teams, message} = properties.notification;

      if (properties?.isLF === true) {
        this.props.setAsyncSpotlightCreate({
          teams,
          message,
          fileDetails: properties.fileDetails,
        });
      } else {
        this.props.createSpotlightWithFile({
          teams,
          message,
          fileDetails: properties.fileDetails,
        });
      }
    }

    const queryParams = utils.parseQs(this.props.location.search);
    queryParams.nonce = Date.now();
    delete queryParams.ordering;
    let params = utils.stringifyQs(queryParams, {addQueryPrefix: true});

    // Handles the case when user uploads a file from the edit asset modal
    if (properties?.fromEdit) {
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: params,
        state: {assetId: properties.assetId},
      });
      return;
    }

    /**
     * Handles the case when user uploads a file from the wiki page
     * by clicking the new button.
     */
    if (this.props.location.pathname !== '/app/wiki/') {
      this.props.history.push(`/app/wiki/`);
      return;
    }

    /**
     * Handles the case when user uploads a file from the wiki page
     * with topic using the new button.
     */

    if (queryParams.topic && queryParams.tag) {
      const topicToRefresh = properties?.selectedTopics.find(
        (topic) => topic.value === queryParams.topic
      );
      if (!topicToRefresh) {
        delete queryParams.tag;
        delete queryParams.topic;
        params = utils.stringifyQs(queryParams, {addQueryPrefix: true});
      }
    }

    this.props.history.push(`/app/wiki/${params}`);
  };

  render() {
    if (this.state.needLogin) {
      if (this.props.location.search === '?chrome') {
        return <Redirect to='/login?chrome' />;
      }
      return (
        <Redirect
          to={
            '/login?redir=' +
            encodeURIComponent(
              this.props.history.location.pathname +
                this.props.history.location.search +
                this.props.history.location.hash
            )
          }
        />
      );
    }
    let loaderStyles = {
      main: {
        height: '100vh',
        backgroundColor: '#FFF',
        textAlign: 'center',
      },
      container: {
        width: '0px',
        margin: 'auto',
        paddingTop: 'calc(50vh - 12px)',
        height: '24px',
      },
    };
    if (this.state.loading || !this.props.me) {
      return (
        <div style={loaderStyles.main}>
          <div style={loaderStyles.container}>
            <PropagateLoader loading={true} size={24} color='#588EEB' />
          </div>
        </div>
      );
    }
    let styles = {
      main: {
        fontFamily: 'Open Sans, sans-serif',
        display: 'grid',
        width: '100%',
        gridTemplateColumns: 'auto 1fr',
        height: 'calc(100vh - 78px)',
      },
      navigationBar: {
        width: '100%',
      },
      mainGridWithOneColumn: {
        fontFamily: 'Open Sans, sans-serif',
      },
      menuIcon: {
        display: 'inline-block',
        margin: '15px 20px 15px 0px',
        color: '#C0C5D2',
      },

      content: {
        backgroundColor: '#FFF',
        overflow: 'auto',
        padding: '0px',
      },
      contentNotPaywall: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      mainModal: {
        width: '1000px',
        padding: '0',
      },
      businessTermModal: {
        padding: '0 40px',
      },
    };
    let items = [
      this.props.location.pathname !== '/app/license_expired' &&
      this.props.location.pathname !== '/app/locked_out'
        ? {
            active: this.props.location.pathname.includes('profile') || false,
            icon: faUserCircle,
            label: 'Profile',
          }
        : null,
      {
        icon: faSignOutAlt,
        label: 'Sign out',
      },
    ];

    const {pathname} = this.props.location;

    const navItems = getNavItems(
      permissions,
      this.props.flag,
      pathname,
      this.props.me.role
    );

    return (
      <Fragment>
        <StateManager stateUpdate={this.state.stateUpdate} />
        {this.props.location.pathname !== '/app/wiki/print/' &&
        this.props.location.pathname !== '/app/interstitial' &&
        this.props.location.pathname !== '/app/ssoError' ? (
          <>
            <div style={styles.navigationBar}>
              <AppNavigation />
            </div>

            <div style={styles.main}>
              {/* LEFT_PANEL */}
              <>
                <SideNavigation items={navItems} />
                <StatusBar
                  menuOpen={this.state.menuOpen}
                  openProfileMenu={this.handleMenuClick}
                />
                <Popover
                  open={this.state.menuOpen}
                  autoCloseOnBlur
                  onRequestClose={this.handleMenuClick}
                  anchorEl={this.state.menuAnchor}
                  verticalOffset={-175}
                  horizontalOffset={6}
                >
                  {items &&
                    items
                      .filter((i) => !!i)
                      .map((item) => {
                        return (
                          <MenuItem
                            key={item.label}
                            disabled={!!item.disabled}
                            active={!!item.active}
                            onTouchTap={this.handleCogMenu.bind(this, item)}
                            label={
                              <div>
                                {item.icon ? (
                                  <Fontawesome
                                    name={item.icon}
                                    style={
                                      item.active
                                        ? {
                                            ...styles.menuIcon,
                                          }
                                        : styles.menuIcon
                                    }
                                  />
                                ) : null}
                                <span>{item.label}</span>
                              </div>
                            }
                          />
                        );
                      })}
                </Popover>
              </>
              <div id='content' style={styles.content}>
                {this.props.paywall ? (
                  <Suspense fallback={<div />}>
                    <div>
                      {this.props.paywall === 'unlockPremium' ? (
                        <UnlockPremium />
                      ) : this.props.paywall === 'limitExceeded' ? (
                        <LimitExceeded />
                      ) : this.props.paywall === 'licensesExpired' ? (
                        <LicensesExpired />
                      ) : this.props.paywall === 'licensesExceeded' ? (
                        <LicensesExceeded />
                      ) : this.props.paywall === 'accountBlocked' ? (
                        <AccountBlocked />
                      ) : this.props.paywall === 'lockedOut' ? (
                        <LockedOut />
                      ) : null}
                    </div>
                  </Suspense>
                ) : null}
                <div style={styles.contentNotPaywall}>
                  {this.props.me.is_demo ? (
                    <div>
                      <DemoBanner />
                    </div>
                  ) : null}
                  {this.props.me.about_to_expire ? <RenewBanner /> : null}
                  {!this.state.isExtension ? <ChromeBanner /> : null}
                  {this.props.me.impersonated ? (
                    <StopImpersonateBanner
                      onClick={() => this.stopImpersonate()}
                      firstName={this.props.me.first_name}
                      lastName={this.props.me.last_name}
                    />
                  ) : null}
                  {this.props.children}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div style={styles.mainGridWithOneColumn}>{this.props.children}</div>
        )}
        {this.props.flag.hasNestedTopicsFlag ? <TopicModal /> : <TopicForm />}
        <AssetModal
          isOpen={this.props.assetUploadModal.isOpen}
          editableAsset={this.props.assetUploadModal.editableAsset}
          editableFile={this.props.assetUploadModal.editableFile}
          onClose={this.handleAssetClose}
          track={track}
          selectedFiles={this.props.assetUploadModal?.selectedFiles || null}
          hasLargeFileUploadFlag={this.props.flag.hasLargeFileUploadFlag}
          hasExtendedFileTypesFlag={this.props.flag.hasExtendedFileTypesFlag}
          session={this.props.me}
        />

        {this.props.contentImportModal.isOpen && (
          <ContentImportModal
            session={this.props.me}
            isOpen={this.props.contentImportModal.isOpen}
            onClose={this.props.hideContentImportModal}
            hasContentIntegrationSharepointFlag={
              this.props.flag.hasContentIntegrationSharepointFlag
            }
          />
        )}
        <WithSpotlight
          isOpen={
            this.props.spekModal.isShowing ||
            this.props.openCloneSpek ||
            (this.props.openSpekWithTopic && this.props.openSpekWithTopic.topic)
          }
          onClose={() => {
            this.props.hideCreateSpekModal();
            this.props.cloneSpek(null, false);
            this.props.createSpekWithTopic(null, null);
          }}
          onSave={() => {
            this.props.hideCreateSpekModal();

            this.goToWiki();

            this.props.cloneSpek(null, false);
            this.props.createSpekWithTopic(null, null);
          }}
          {...this.props}
        />
        {this.props.spotlightModal.isOpen && (
          <Overlay
            style={{
              backgroundColor: 'rgba(3, 9, 18, 0.7)',
              backdropFilter: 'blur(5px)',
            }}
          >
            <Modal open={true} style={styles.mainModal}>
              <ConnectedSpotlightModal
                notify={this.handleNotify}
                track={track}
                spotlightId={this.props.spotlightModal.spotlight}
                knowledgeCheck={this.props.spotlightModal.knowledgeCheck}
                flow={this.props.spotlightModal.flow}
                message={this.props.spotlightModal.message}
                teams={this.props.spotlightModal.teams}
                fileDetails={this.props.spotlightModal.fileDetails}
                handleSpotlightDelete={this.handleSpotlightDelete}
                handleSpotlightClose={this.handleSpotlightClose}
                {...this.props}
              />
            </Modal>
          </Overlay>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.layout,
  wikiStoredState: state.wikiStoredState.storedState,
  pushNotifications: state.pushNotifications,
  topicIndex: state.topicIndex,
  spotlightModal: state.spotlightModal,
  flag: state.flag,
  openCloneSpek: state.cloneSpek.openCloneSpek,
  openSpekWithTopic: state.createSpekWithTopic,
  roles: state.roleInput,
  fieldTypeSelect: state.FieldTypeSelect,
  userStages: state.userStages,
  spekModal: state.spekModal,
  assetUploadModal: state.assetUploadModal,
  contentImportModal: state.contentImportModal,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => {
    dispatch(updateUser(user));
  },
  updateWikiStoredState: (stateUpdate) => dispatch(updateWikiStoredState(stateUpdate)),
  updateUnreadCount: (count) => dispatch(updateUnreadCount(count)),
  setRoles: (roles) => dispatch(setRoles(roles)),
  setFieldTypes: (fieldTypes) => dispatch(setFieldTypes(fieldTypes)),
  setUserStages: (stages) => dispatch(setUserStages(stages)),
  closeSpotlightModal: () => dispatch(closeSpotlightModal()),
  setFlags: (feature) => dispatch(setFlags(feature)),
  setFeatures: (features) => dispatch(setFeatures(features)),
  cloneSpek: (termToClone, openCloneSpek) => {
    dispatch(cloneSpek(termToClone, openCloneSpek));
  },
  createSpekWithTopic: (topic, topicId) => dispatch(createSpekWithTopic(topic, topicId)),
  showSpotlightModal: () => dispatch(showSpotlightModal()),
  createSpotlightWithFile: (payload) => dispatch(createSpotlightWithFile(payload)),
  showTopicModal: (term) => dispatch(showTopicModal(term)),
  showPaywall: (paywall, paywallType) => dispatch(showPaywall(paywall, paywallType)),
  showCreateSpekModal: () => dispatch(showCreateSpekModal()),
  hideCreateSpekModal: () => dispatch(hideCreateSpekModal()),
  showAssetUploadModal: () => dispatch(showAssetUploadModal()),
  hideAssetUploadModal: () => dispatch(hideAssetUploadModal()),
  setAsyncSpotlightCreate: (payload) => dispatch(setAsyncSpotlightCreate(payload)),
  showContentImportModal: () => dispatch(showContentImportModal()),
  hideContentImportModal: () => dispatch(hideContentImportModal()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
