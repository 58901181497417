import {
  RiCheckDoubleLine,
  RiFolder3Line,
  RiGuideLine,
  RiOrganizationChart,
  RiFile2Line,
  RiUploadCloud2Line,
  RiArticleLine,
  RiGridLine,
  RiImage2Line,
  RiSettings5Line,
  RiPieChartBoxLine,
  RiFilePdf2Line,
} from 'react-icons/ri';
import {VscMegaphone} from 'react-icons/vsc';
import {AIAssistBW, AIAssistColor, AskAIAssist} from './Icons/AIAssist';

export const STEP_DETAILS_STYLES = `
svg {
  box-sizing: content-box;
}
[aria-live]:not([id*="feedback"]), [id*="live-region"] {
  display:none !important;
}
input#next-step {
  border: none;
  outline: none;
  caret-color: transparent;
}`;

export const observerConfig = {childList: true, subtree: true};

const COMMON_ARROW_STYLES = {
  width: '0px',
  height: '0px',
  zIndex: 2147483647,
};
const ARROW_BORDER_TRANSPARENT = '12px solid transparent';
const ARROW_BORDER_WHITE = '12px solid #ffffff';
export const arrowStyles = {
  top: {
    ...COMMON_ARROW_STYLES,
    borderLeft: ARROW_BORDER_TRANSPARENT,
    borderRight: ARROW_BORDER_TRANSPARENT,
    borderTop: ARROW_BORDER_WHITE,
    bottom: -11,
  },
  bottom: {
    ...COMMON_ARROW_STYLES,
    borderLeft: ARROW_BORDER_TRANSPARENT,
    borderRight: ARROW_BORDER_TRANSPARENT,
    borderBottom: ARROW_BORDER_WHITE,
    top: -11,
  },
  left: {
    ...COMMON_ARROW_STYLES,
    borderTop: ARROW_BORDER_TRANSPARENT,
    borderBottom: ARROW_BORDER_TRANSPARENT,
    borderLeft: ARROW_BORDER_WHITE,
    right: -11,
  },
  right: {
    ...COMMON_ARROW_STYLES,
    borderTop: ARROW_BORDER_TRANSPARENT,
    borderBottom: ARROW_BORDER_TRANSPARENT,
    borderRight: ARROW_BORDER_WHITE,
    left: -11,
  },
};

export const ICONS = {
  flow: RiGuideLine,
  spek: RiOrganizationChart,
  topic: RiFolder3Line,
  spotlight: VscMegaphone,
  'knowledge-check': RiCheckDoubleLine,
  'content-integration': RiUploadCloud2Line,
  file: RiFile2Line,
  aiAssist: AIAssistBW,
  aiAssistColor: AIAssistColor,
  askAIAssist: AskAIAssist,

  // Document Types
  document: RiArticleLine,
  presentation: RiPieChartBoxLine,
  spreadsheet: RiGridLine,
  image: RiImage2Line,
  misc: RiSettings5Line,

  // File Types
  pdf: RiFilePdf2Line,
  pptx: RiPieChartBoxLine,
  docx: RiArticleLine,
  doc: RiArticleLine,
  xlsx: RiGridLine,
  xls: RiGridLine,
  csv: RiGridLine,
};
