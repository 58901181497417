import {Flex, Heading, DSButton as Button, Divider, Icon, useToast} from 'spekit-ui';
import {RiCloseLine, RiQuestionLine} from 'react-icons/ri';
import type {ITopicModalState} from './TopicModal';
import {useFormContext} from 'react-hook-form';
import {topics, utils} from 'spekit-datalayer';
import {track} from '../../../../utils/analytics';
import {useHistory, useLocation} from 'react-router-dom';
import {ITopic} from 'spekit-types';
import {hasSomeOf} from '../../../../utils/permissions';
import {DeleteTopic} from './DeleteTopic';

interface HeaderProps {
  topic: ITopic | null;
  handleClose: () => void;
}

const makeTeamSettings = (data: ITopicModalState, topic: ITopic | null) => {
  const canUpdateAllTeams = hasSomeOf(['teams.update_all']);
  const isTopicLocked = topic && data.allTeams && !canUpdateAllTeams;
  const mappedTeams = data.allTeams ? [] : data.teams?.map((t) => t.value);
  if (canUpdateAllTeams) {
    return {
      teams: mappedTeams,
      all_teams: data.allTeams,
    };
  }
  if (isTopicLocked) return {};
  if (topic) return {teams: mappedTeams};
  return {teams: mappedTeams, all_teams: data.allTeams};
};

const getPayload = (data: ITopicModalState, topic: ITopic | null) => {
  return {
    name: data.name,
    description: data.description,
    font_awesome_id: data.icon.fontawesome,
    icon: data.icon.image,
    parents: data.topics.map((t) => t.value),
    ...makeTeamSettings(data, topic),
    domains: data.domains
      ?.filter((domain) => domain.name)
      .map((domain) => ({
        name: domain.name,
        include_subdomain: domain.includeSubdomain,
        paths:
          domain.includePaths && domain.paths.length > 0
            ? domain.paths
                .filter((path) => path.value)
                .map((path) => ({path: path.value, rule: 'contains'}))
            : [],
      })),
  };
};

export const Header = (props: HeaderProps) => {
  const {handleSubmit} = useFormContext<ITopicModalState>();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const {topic, handleClose} = props;

  const forceRefresh = (route: string, search?: string) => {
    history.push('/empty');
    history.replace({pathname: route, search});
  };

  const editTopic = async (data: ITopicModalState) => {
    const payload = getPayload(data, topic);
    try {
      const updatedTopic = await topics.editTopic(topic!.id, payload);
      toast({
        variant: 'success',
        description: `${updatedTopic.name} updated`,
      });
      track('Topic Edited', {
        screen_name: 'Topic form',
        ...updatedTopic,
      });
      handleClose();
      const filters = utils.parseQs(location.search);
      if (location.pathname === '/app/wiki/') {
        if (filters.tag) {
          filters.tag = encodeURIComponent(updatedTopic.name);
        }

        history.push({
          pathname: `${location.pathname}`,
          search: utils.stringifyQs(filters),
        });
      } else {
        forceRefresh('/app/wiki/topics', utils.stringifyQs(filters));
      }
    } catch (error) {
      toast({
        variant: 'error',
        description: error?.response?.data?.message,
      });
    }
  };

  const createTopic = async (data: ITopicModalState) => {
    const payload = getPayload(data, null);

    try {
      const createdTopic = await topics.createTopic(payload);
      toast({
        variant: 'success',
        description: 'Topic created',
      });
      track('Topic Created', {
        screen_name: 'Topic form',
        ...data,
      });
      handleClose();
      history.push(
        `/app/wiki/?topic=${createdTopic.id}&tag=${encodeURIComponent(createdTopic.name)}`
      );
    } catch (error) {
      toast({
        variant: 'error',
        description: error?.response?.data?.message,
      });
    }
  };

  const onSubmit = (data: ITopicModalState) => {
    if (topic) {
      editTopic(data);
    } else {
      createTopic(data);
    }
  };

  return (
    <Flex alignItems='center'>
      <Heading
        as='h4'
        fontWeight='semibold'
        fontSize='large'
        data-testid='topic-modal-heading'
      >
        {topic ? 'Edit Topic' : 'Create Topic'}
      </Heading>
      <Flex alignItems='center' ml='auto'>
        <Button
          variant='icon'
          icon={<Icon as={RiQuestionLine} />}
          size='medium'
          aria-label='Help center'
          colorScheme='white'
          tooltipLabel='Help center'
          tooltipPlacement='bottom'
          onClick={() => {
            window.open(
              'https://help.spekit.com/hc/en-us/sections/9922002747803-Topics',
              '_blank'
            );
          }}
          mr={topic ? 8 : 0}
        />
        {topic && (
          <DeleteTopic
            topic={topic}
            handleClose={handleClose}
            forceRefresh={forceRefresh}
          />
        )}

        <Button
          variant='contained'
          size='medium'
          colorScheme='primary'
          ml={12}
          mr={20}
          onClick={handleSubmit(onSubmit)}
        >
          {topic ? 'Save changes' : 'Publish'}
        </Button>
        <Divider orientation='vertical' h='32px' mr={10} />
        <Button
          variant='icon'
          icon={<Icon as={RiCloseLine} />}
          size='medium'
          aria-label='Close'
          colorScheme='white'
          tooltipLabel='Close'
          tooltipPlacement='bottom'
          onClick={handleClose}
        />
      </Flex>
    </Flex>
  );
};
